(<template>
  <div class="applied-actions">
    <admin-only-note v-if="$isGodmode() && !isAutoApproveEnabled"
                     class="applied-actions__award-btn-cont">
      <button class="sk-btn sk-btn--default applied-actions__award-btn"
              @click="catchAwardAsCustomer">{{ $gettext('Award') }}</button>
    </admin-only-note>
    <button class="sk-btn sk-btn--red applied-actions__withdraw-btn"
            @click="openWithdrawAppliedModal">{{ $gettext('Withdraw from assignment') }}</button>
    <a v-if="supportPortalLink"
       :href="supportPortalLink"
       class="sk-btn sk-btn--white apply-actions__btn"
       target="_blank">{{ $gettext('Frequently asked questions') }}</a>
  </div>
</template>)

<script>
  import {mapState} from 'vuex';
  import AdminOnlyNote from '@/components/shared_components/AdminOnlyNote';
  import JobActionsPrototype from '@/prototypes/JobActionsPrototype';

  export default {
    components: {
      'admin-only-note': AdminOnlyNote
    },
    extends: JobActionsPrototype,
    data() {
      return {
        supportPortalLink: this.$getString('domainData', 'supportPortalLink')
      };
    },
    computed: {
      ...mapState('OneAssignmentStore', {
        isAutoApproveEnabled: ({storeJobObj}) => {
          return storeJobObj && storeJobObj.priceRequirement ? storeJobObj.priceRequirement.autoApprove : '';
        }
      })
    },
    methods: {
      catchAwardAsCustomer() {
        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        this.$store.dispatch('OneAssignmentStore/awardAsCustomer', {
          jobId: this.$route.params.id,
          supplierId: this.$store.getters['UserInfoStore/userEntityId']
        }).then(() => {
          const jobId = this.$route.params.id;
          const promiseArr = [
            this.$store.dispatch('OneAssignmentStore/getJobById', jobId),
            this.$store.dispatch('OneAssignmentStore/getDiscussions', jobId)
          ];
          Promise.all(promiseArr).then(() => {
            this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
          });
        }).catch(() => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        });
      }
    }
  };
</script>

<style scoped>
  .applied-actions {
    display: block;
    max-width: 265px;
    margin-top: 30px;
  }

  .applied-actions__withdraw-btn,
  .applied-actions__award-btn-cont {
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    .applied-actions {
      max-width: 100%;
    }
  }
</style>
